import React from "react"

import styles from "./content.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

const ContentPage2 = () => {
  return (
    <AccountGuard>
      <div className={`${styles.header}`}>
        <h2>Technical Official Introductory</h2>
      </div>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sectionHeader}`}>
          <h3>Section 02</h3>
          <h1>Technical Official: Basic Course</h1>
        </div>
      </div>

      <div className={`${styles.contentWrapper}`}>
        <ul className={`${styles.content}`}>
          <li>
            <p>
              This course is designed as preparation for the Technical Official
              I or Technical Official II Role for International Level
              Competition at International Functional Fitness Federation (iF3)
              sanctioned events.
            </p>

            <p>
              Technical Official I and Technical Official II positions are the
              “on field” face of the technical committee. In order to
              successfully complete this course, the participant must complete
              an assessment consisting of an online examination in addition to
              positive participation during class discussion and demonstrations.
            </p>

            <p>
              The certification provided through this course will remain valid
              for a period of three (3) years. This preliminary certification
              required for participation in additional courses available through
              the iF3.
            </p>

            <p>
              <b>NOTE:</b> International accreditation is only provided when
              this course this course is delivered in conjunction with an in
              person seminar led by iF3 personnel. National Federations may use
              this course in part or in its entirety to certify Technical
              Officials at the national level.
            </p>

            <p>
              Accreditation may be revoked at the discretion of the iF3
              Technical Official Committee or Board of Directions.
            </p>

            <p>This course will cover the following topics:</p>

            <p>
              <b>Technical Officials Standard Manual</b>
            </p>

            <p>
              As an iF3 Technical Official, there are critical expectations
              regarding behavioral and professional standards to which each
              certified Technical Official is expected to adhere while
              participating in iF3 events.
            </p>

            <p>
              Additionally, iF3 Technical Officials’ Standard Manual includes
              best practices and an overview of the performance review routines
              that will be used in Technical Official evaluations. The manual
              will be periodically updated with addenda to address topics not
              included in initial publication.
            </p>

            <p>
              <b>Movement Standards</b>
            </p>

            <p>
              Movement Standards identify the key points of performance that
              must be achieved by the athlete so that credit may be given for
              the completed repetition.
            </p>

            <p>
              During this course you will be provided with the iF3 IF100
              Technical Official Movement Standard Doc. This is a general guide
              for all movements you will need to be familiar with. Specific
              event programming will be provided by the National Federal, iF3 or
              in some cases this online course. If this is not in direct
              preparation, previous tests and related programming should be used
              for training examples and discussion.
            </p>
          </li>
        </ul>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.previous}`}>
            <a href="/courses/toi/content">
              <i
                className={`fas fa-arrow-alt-circle-left uk-margin-small-right`}
              />
              Previous
            </a>
          </div>
          <div className={`${styles.next}`}>
            <a href="/courses/toi/content/3">
              Next
              <i
                className={`fas fa-arrow-alt-circle-right uk-margin-small-left`}
              />
            </a>
          </div>
        </div>
      </div>
    </AccountGuard>
  )
}

export default ContentPage2
